<template>
  <div>
    <v-card>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>

      <v-skeleton-loader class="ma-3 pa-2" v-if="init_loading" v-bind="attrs" type="list-item-three-line"></v-skeleton-loader>
      <div v-else>
         <v-row  class="py-2 px-5" justify="space-between">
        <h3>Placement / Internship Report</h3>
        
         <v-btn
                        color="blue"
                        dark
                        @click="exceldialog = !exceldialog"
                        >Excel Import</v-btn
                      ></v-row>
                       <v-dialog width="350" persistent v-model="exceldialog">
                        <v-card>
                          <v-card-title class="c-title"
                            >Excel import</v-card-title
                          >
                          <v-card-text
                            ><br />
                            <v-row justify="end">
                              <a :href="url">
                                <v-icon
                                  large
                                  color="blue"
                                  class="icon-quick"
                                  title="Download"
                                >
                                  mdi-file-excel
                                </v-icon>
                              </a>
                            </v-row>
                            <v-file-input
                              v-model="excelfile"
                              label="Import Excel"
                            ></v-file-input>
                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="clear()" text>cancel</v-btn>
                              <v-btn @click="uploadexcel()" dark color="blue"
                                >Upload</v-btn
                              >
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      
                      <v-dialog
                        width="1000"
                        persistent
                        v-model="excelerrordialog"
                      >
                        <v-card>
                          <v-card-title class="c-title">
                            <v-row justify="space-between">
                              <b>Excel Error</b>
                              <v-btn icon dark @click="closeerrordialog">
                                <v-icon color="black">mdi-close</v-icon>
                              </v-btn>
                            </v-row>
                          </v-card-title>
                          <v-card-text
                            ><br /><br />
                        
                            <table 
                            style="width:100%"
                            
                             v-if="
                                Array.isArray(notfoundstudent) &&
                                notfoundstudent.length
                              "
                              class="pr-5"
                              justify="center">
                            <thead>
                            <th style="font-size:35px;">Grno</th>
                            <th style="font-size:35px;">Error</th>
                            </thead>
                            <tbody >
                              <tr class="ma-5" v-for="(item,index) in notfoundstudent" :key=index>
                                  <td class="text-center"> {{item.grno}} </td>
                                   <td  class="text-center red--text"> {{item.error}} </td>
                              </tr>
                            </tbody>
                            </table>

                                                    
                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="closeerrordialog()" text
                                >Close</v-btn
                              >
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
   
      <v-row>
        <v-col>
          <v-card-text class="subtitle-2 text-right"><small style="color:red;">*</small>&nbsp;Offer Type</v-card-text>
        </v-col>
        <v-row>
          <v-checkbox 
            v-for=" (role,index) in placement_type_list"
            :key="index"
            :label="placement_type_list[index].name"
            color="success"
            
            v-model="placement_type_list[index].value"
          ></v-checkbox>
          <!-- <span v-if="addrole.length ==0">
            <v-chip>
              <v-icon left color="red">mdi-exclamation-thick</v-icon>Please select roles
            </v-chip>
          </span>-->
        </v-row>
      </v-row>

      <v-row class='pl-4'>
        <v-col md="2">
          <label>Academic Year  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete :items="ay_list" item-text="name" v-model="ay"> </v-autocomplete>
        </v-col>
        <v-col md="2">
         <label>Semester  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete  :items="sem_list" item-text="name" v-model="sem"></v-autocomplete>
        </v-col>
        <v-col md="2">
           <label>Company  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete      
            :items="company_list"
            item-value="id"
            item-text="name"
            v-model="company"
          ></v-autocomplete>
        </v-col>
        <v-col md="2">
           <label>Institute  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete         
            :items="institute_list" item-text="name" item-value="id"
            v-model="institute"
            @change="getPrograms()"
          ></v-autocomplete>
        </v-col>
        <v-col md="2">
           <label>Program  &nbsp;<small style="color:red;">*</small></label>
          <v-autocomplete
            autocomplete="off" 
            :items="program_list"
            item-text="name" item-value="id"
            
            v-model="program"
          ></v-autocomplete>
        </v-col>
        <!-- <v-col sm="2">
           <label>Offer Accepted&nbsp;<small style="color:red;">*</small></label> -->
           <!-- <v-checkbox v-model="offeraccepted" color="success" label="Offer Accepted"></v-checkbox> -->
          <!-- <v-autocomplete
            autocomplete="off" 
            :items="offer_list"
            item-text="name" item-value="id"
            v-model="offer"
          ></v-autocomplete>
        </v-col>
        <v-col sm="2"></v-col><v-col sm="3"></v-col> -->
        <v-col sm="2">
          <v-btn class="mt-6" color="primary darken-1" @click="fetchReport()">Fetch Report</v-btn>
        </v-col>
      </v-row>
      </div>
    </v-card>
    <br />
     
    <div>
     <!-- <v-data-table :headers="headers" :items="stud_list" class="elevation-1" :search="search" v-if="stud_list.length!=0">
        <template v-slot:top>
          <v-toolbar flat color="white" align="center" justify="center">
            <v-toolbar-title>Report</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <download-excel
              class="mar"
              v-if="stud_list.length != 0"
              color="primary"
              dark
              :data="stud_list"
              :fields="table_fields"
              worksheet="My Worksheet"
              name="Placement Report.xls"
            >
              <!-- <v-btn class="ma-2 no-display" tile outlined color="success">
                <v-icon left>mdi-file-excel</v-icon>Excel
                <v-icon right>mdi-download</v-icon>
              </v-btn>-->
             <!-- <v-btn color="success" dark class="mb-2">
                <v-icon dark>mdi-file-excel</v-icon>
              </v-btn>
            </download-excel>
          </v-toolbar>
        </template>
      </v-data-table>-->
      
       <v-row justify="end" class="mr-5">
        <v-btn v-if="stud_list.length != 0" style="margin-right:20px" color="blue" dark @click="downloadallOffer(stud_list)"
                        >Download Offerletters</v-btn
                      >
        <v-btn v-if="stud_list.length != 0"  @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
      </v-row>
      
      <v-simple-table id="exceltable"  class="elevation-1" :search="search" v-if="stud_list.length!=0">
       
        <template v-slot:default>
          <thead>
            <tr>
              <th  @click="sortTable('srno')" class="text-left"> Sr. No. </th>
              <th  @click="sortTable('registration_number')" class="text-left"> PRN/GR. No. </th>
              <th  @click="sortTable('name')" class="text-left"> Name</th>
              <th  @click="sortTable('email')" class="text-left"> Email</th>
              <th  @click="sortTable('cont_no')" class="text-left"> Contact No.</th>
              <th  @click="sortTable('company')" class="text-left"> Company</th>
              <th  @click="sortTable('offer_type')" class="text-left"> Offer Type</th>
              <th  @click="sortTable('department')" class="text-left"> Department</th>
              <th  @click="sortTable('branch')" class="text-left"> Branch</th>
              <th  @click="sortTable('year')" class="text-left"> Year</th>
              <th  @click="sortTable('is_accepted_by_learner')" class="text-left"> Is accepted by learner</th>
              <th  @click="sortTable('offerletter')" class="text-left"> Offer Letter</th>
              <th  @click="sortTable('organization')" class="text-left"> Organization</th>
              <th   class="text-left">Offer letter upload
              </th>
               
              <!-- <v-col cols="12" sm="12">
                                        <label>CV File :</label>
                                        <v-file-input type="file" placeholder="Select CV file" prepend-icon="mdi-file" ref="file" show-size v-model="savedata.file" required="true"
                                        clearable
                                        ></v-file-input>
                                    </v-col> -->
            </tr>
            
          </thead>
          <tbody>
          
            <tr
              v-for="item in stud_list"
              :key="item.id"
            >
              <td>{{ item.srno }}</td>
              <td>{{ item.registration_number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.cont_no}}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.offer_type }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.branch }}</td>
              <td>{{ item.year }}</td>
               <td><span v-if="item.is_accepted_by_learner == true">
                    YES</span>
                    <span v-else>
                    NO</span>
                    </td>
              
              <td>
                
                <span v-if="item.offerletter != null" style="font-size:25px;color:DodgerBlue;">
                  <i class="mdi mdi-arrow-down-bold-circle-outline" @click="download(item)"
                  aria-hidden="true"></i>            
                </span>
                <span v-else>
                  -
                </span>
              </td>
              <td>{{ item.organization}}</td>
               <td>
                    <span  v-if="item.offerletter == null" style="font-size:25px;color:DodgerBlue;">
                    <i class="mdi mdi-arrow-up-bold-circle-outline" @click="dialogicon(item)"
                    aria-hidden="true"></i>            
                    </span>
                    <span v-else>
                    -
                    </span>
                     <!-- dialog upload start -->
                      <!-- <v-btn
                        color="blue"
                        dark
                        @click="dialog1 = !dialog1"
                        >upload</v-btn
                      ></v-row> -->
            
                         
                    <!-- dialog upload end -->
               </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>


       <v-dialog width="350" persistent v-model="dialog1">
                        <v-card>
                          <v-card-title class="c-title"
                            >Upload Offer letter</v-card-title
                          >
                          <v-card-text
                            ><br />
                           
                            <v-col cols="12" sm="12">
                      <label>Offer letter:</label>
                      <v-file-input type="file" placeholder="Select Offer letter" prepend-icon="mdi-file" ref="file" show-size  v-model="savedata.file" required="true"
                      clearable
                      ></v-file-input>
                      <v-btn color="primary darken-1"  outlined text @click="dialog1=false">Cancel</v-btn>
                       <v-btn style="margin-left:60px" color="primary darken-1" dark @click="save()">Save</v-btn>
                  </v-col>
                            </v-card-text>
                          
                        </v-card>
                      </v-dialog>
                      
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {table2excel} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
export default {
  data: () => ({
    init_loading:false,
    dialogadd:false,
    reg:"",
    com:"",
    // dialog_icon:false,
     savedata: {
                file: null,
                id:"",
            },
    id:"",
    ascending: false,
        sortColumn: '',
    snackbar_msg: "",
    color: "",
    offerfile:"",
    offerdialog:false,
    uploadOffer:false,
    snackbar: false,
    placement_type_list: [],
    stud_list: '',
    ay_list: [],
    sem_list: [],
    company_list: [],
    institute_list: [],
    program_list: [],
    search: "",
    ay: "",
    sem: null,
    offeraccepted:false,
    company: null,
    institute: null,
    program: null,
    offer_type: [],
    offter_index:[],
    headers: [
      { text: "Sr. No.", value: "id", sortable: true },
      { text: "GR. No.", value: "registration_number", sortable: true },
      { text: "Name", value: "name", sortable: true },
      { text: "Company", value: "company", sortable: true },
      { text: "Offer Type", value: "offer_type", sortable: true },
      { text: "Department", value: "department", sortable: true },
      { text: "Branch", value: "branch", sortable: true },
      { text: "Year", value: "year", sortable: true },
      { text: "Offerletter", value: "offerletter", sortable: true },
      { text: "Organization", value: "organization", sortable: true },
       { text: "Offer letter upload", value: "newfile", sortable: true }
    ],
    table_fields: {
      "Sr. No.": "id",
      "GR. No.": "registration_number",
      Name: "name",
      "Company Name": "company",
      "Offer Type": "offer_type",
      Department: "department",
      Branch: "branch",
      Year: "year",
      Organization: "organization"
    },
  excelfile: null,
    exceldialog: false,
    dialog1:false, 
    url: "",
    excelerrordialog: false,
    notfoundstudent: [],

  }),
  mounted() {
    this.init_loading=true;
    axios
      .post("/TPO/placementReport")
      .then(res => {
        this.url = res.data.url;
        if (res.data.msg == "200") {
          this.init_loading=false;
          // console.log("Data passed->");
          // console.log(res.data.student_list);
         // this.stud_list = res.data.student_list;
          this.ay_list = res.data.ay_list.sort();
          this.sem_list = res.data.sem_list.sort();
          this.company_list = res.data.company_list;
          this.institute_list = res.data.institute_list;
          this.program_list = res.data.allprogram_list;
          this.placement_type_list = res.data.placement_type_list;
          // this.offer_list.push({
          //           name: 'Accepted',
          //           text: ''
          // })
          //alert(this.placement_type_list)
          // for(var i in this.placement_type_list)
          // {
           
          //   this.offter_index[i]=true;
          // }
         // this.company_list.push("All");
         // this.program_list.push("All");
           this.company_list.push({
                    name: 'All',
                    text: ''
          })
          this.company="All"

          this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"
          
          this.sem_list.push({
                    name: 'All',
                    text: ''
          })
          this.sem="All"
          this.ay_list.push({
                    name: 'All',
                    text: ''
          })
          this.ay="All"
           this.institute_list.push({
                    name: 'All',
                    text: ''
          })
          this.institute="All"
          
           
           
          
          //console.log(" fetching data!");
        } else {
          //console.log("error fetching data!");
          this.init_loading=false;
        }
      });
      // .catch(error => {
      //   this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
      //   window.console.log(error);
      // });
  },
  computed: {
    "columns": function columns() {
      if (this.stud_list.length == 0) {
        return [];
      }
      return Object.keys(this.stud_list[0])
    }
  },
  methods: {
    sortTable(col) {
            console.log(col);
            if (this.sortColumn === col) {
                console.log(col);
                this.ascending = !this.ascending;
            } else {
                this.ascending = true;
                this.sortColumn = col;
            }

            var ascending = this.ascending;

            this.stud_list.sort(function(a, b) {
                if (a[col] > b[col]) {
                return ascending ? 1 : -1
                } else if (a[col] < b[col]) {
                return ascending ? -1 : 1
                }
                return 0;
            })
        },
    download(item){
       var data = {
        student: item,
      };

      axios.post("/TPO/downloadofferletter", data).then((res) => {
        if (res.data.msg == "200") {
          window.open(res.data.cvurl, "_blank");
        }
      });
    },


      downloadallOffer(corl_list) {     
     //alert("on download ")
      var params = {
        stud_list: corl_list,
      };
      axios.post("/onCampusProcess/downloadallOffer", params).then((res) => {
        if (res.data.msg == "200") {
            //console.log(res.data.offerlist);
             const zip = new JSZip();
          let count = 0;
          const zipFilename = "offer.zip";
          var offer= res.data.offerlist;
          offer.forEach(async function (url){
            // const urlArr = url.split('?')[0].split("/")
            // const filename = urlArr[urlArr.length - 1];
            // console.log(filename)
        
            JSZipUtils.getBinaryContent(url.url, function (err, data) {
                  if(err) {
                    console.log(err);
                      throw err; 
                      // or handle the error
                  }
                  zip.file(url.grno, data, {binary:true});
                  count++;
                  if (count === res.data.offerlist.length) {
                      zip.generateAsync({type:'blob'}).then(function(content) {
                          saveAs(content, zipFilename);
                      });
                  }
              });

          });
        }
      });
    },












    uploadOfferDialog(){
      this.uploadOffer=true;
    },
     exportexcel() {    
        $("#exceltable").table2excel({  
          name: "Worksheet Name",
          filename: "Placement_REPORT", //do not include extension
          fileext: ".xls" // file extension
        });        
      },
     
 uploadexcel() {
 
      var studentlist=[];
      
      readXlsxFile(this.excelfile).then((rows) => {
        //console.log(rows);
        var i;
        for (i = 1; i < rows.length; i++) {
      var temp={};
          temp['PRN_Email_id']=rows[i][0];
          temp['companycode']=rows[i][1];
        studentlist.push(temp);
        }
          
      var params={"studentlist":studentlist};
         axios.post("/TPO/applycompanyexcel1",params).then((res) => {
        if (res.data.msg == "200") {
          // this.fetchReport();
          //console.log(res.data.errorlist.length)
          if(res.data.errorlist.length!=0){
              this.excelerrordialog=true;
              this.notfoundstudent=res.data.errorlist;
          }
          else{
 this.showSnackbar("green", "Success.."); 
          }
          

        } else {
          this.showSnackbar("red", res.data.msg); 
        }
      });

      });

this.exceldialog=false;
    },

   dialogicon(item){
         //  alert("helooooo");
          //  alert(item.registration_number);
           this.reg=item.registration_number;
           this.com=item.company;
      this.dialog1=true;
   },

 save() {
           
                if(this.savedata.file){
                      // this.showSnackbar("#b71c1c", "Please Select A File...");
                       let formData = new FormData();
                    formData.append("file", this.savedata.file);
                    formData.append("item",this.com);
                     formData.append("reg",this.reg);
                    
                    this.dialogadd = false;
                   // this.dialog_upload = true;
                    axios
                        .post("/TPO/saveCVfile", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.code == "success") {
                               // this.onLoad();
                                this.dialog1 = false;
                                this.showSnackbar("#4caf50", "Offer letter Added Successfully..."); // show snackbar on success
                            } else {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", "Offer letter  Already Present!!!");
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });

                    this.close();
                
                }
                 else {
                   this.showSnackbar("#b71c1c", "Please Select A File...");
                 }
            }, // end of Save()

    fetchReport() {
    
     
     // console.log("in data "+ data);
      if (this.ay == null) {
        this.showSnackbar("#b71c1c", "Please select Academic Year!"); // show snackbar
      }  else if (this.company == null) {
        this.showSnackbar("#b71c1c", "Please select Company Name!"); // show snackbar
      } else if (this.institute == null) {
        this.showSnackbar("#b71c1c", "Please select Institute!"); // show snackbar
      } else if (this.program == null) {
        this.showSnackbar("#b71c1c", "Please select Progam Name!"); // show snackbar
      } else {
           var x=0;
           //console.log("liiissstttt");
           //console.log(this.placement_type_list);
        for(var i in this.placement_type_list)
        {
            //console.log("(this.placement_type_list[i].value");
            //console.log(this.placement_type_list[i].value);
          if(this.placement_type_list[i].value == true)
          {
            this.offer_type[x]=this.placement_type_list[i].name;
             x++;
          }
         
        }
        if(x == 0){
          this.showSnackbar("#b71c1c", "Please Select Offer Type!");
          return;
        }
        // console.log(`placement type list= ${this.placement_type_list}`)
        // console.log(`and offer type== ${this.offer_type}`)
           const data = {
        ay: this.ay,
        sem: this.sem,
        company: this.company,
        institute: this.institute,
        program: this.program,
        offer_type: this.offer_type,
        offeraccepted : this.offeraccepted,
      };

        axios
          .post("/TPO/getPlacementReport", data)
          .then(res => {
            if (res.data.msg == "200") {
              //console.log("Data passed->");
              this.stud_list = res.data.stud_list;
              this.ay_list = res.data.ay_list;
              this.sem_list = res.data.sem_list;
              this.company_list = res.data.company_list;
              this.institute_list = res.data.institute_list;
              this.program_list = res.data.program_list;
              this.placement_type_list = res.data.placement_type_list;



               this.company_list.push({
                    name: 'All',
                    text: ''
          })
          this.company="All"
          this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"
          this.sem_list.push({
                    name: 'All',
                    text: ''
          })
          this.sem="All"
          this.ay_list.push({
                    name: 'All',
                    text: ''
          })
          this.ay="All"
           this.institute_list.push({
                    name: 'All',
                    text: ''
          })
          this.institute="All"
              
              //console.log("res.data.stud_list::::"+res.data.stud_list.length);
              if (res.data.stud_list.length == 0) {
                this.stud_list=''
                this.showSnackbar(
                  "#b71c1c",
                  "No students found for selected criteria!"
                );
                  //console.log("res.data.stud_list.length == 0"); // show snackbar
              }
            } else if (res.data.msg == "SNR") {
              this.stud_list=''
              this.showSnackbar("#b71c1c", "No students Registered For Selected Criteria!"); // show snackbar
               //console.log("res.data.stud_list.length  == SNR");
            } else if (res.data.msg == "PNA") {
              this.stud_list=''
              this.showSnackbar(
                "#b71c1c",
                "No students found for selected program!"
              ); // show snackbar
            } else if (res.data.msg == "OTNS") {
              this.stud_list=''
              this.showSnackbar("#b71c1c", "Please select Offer Type!"); // show snackbar
            } else if (res.data.msg == "CNAA") {
              this.stud_list=''
              this.showSnackbar(
                "#b71c1c",
                "No company found for Report from given list!"
              ); // show snackbar
            } 
            else if (res.data.msg == "CNA") {
              this.stud_list=''
              this.showSnackbar(
                "#b71c1c",
                "No students found for selected offer type!"
              ); // show snackbar
            }
            else {
              this.stud_list=''
              this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
              //console.log("error fetching data!");
            }
          })
          .catch(error => {
            this.stud_list=null
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            //window.console.log(error);
          });
      }   
    },
    getPrograms(){
     // alert("in get programs"+this.institute);     
           const data = {
        instituteid: this.institute,
      };
        axios
        .post("TPO/getPrograms",data)
        .then((res) => {
          if (res.data.msg == "200") {
           this.program_list = res.data.program_list;
            this.program_list.push({
                    name: 'All',
                    text: ''
          })
          this.program="All"
          } else {
           this.showSnackbar("green", res.data.msg1);
          }
        })
        .catch((error) => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          // this.get_ay_std_ft_loader = false;
        });
      
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
        clear() {
      this.excelfile = null;
      this.exceldialog = false;
    },
       closeerrordialog() {
      this.clear();
      this.excelerrordialog = false;
    },
  }
};
</script>
<style scoped>
table th:hover {
        background: gainsboro;
        cursor: pointer;
      }
.required{
  color:red;
  font-size:14px;
  font-style:bold;
}

</style>    